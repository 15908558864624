import React from 'react';
import { useInView } from 'react-intersection-observer';
import SVG from '../../atoms/SVG';
import Styled from './index.styled';

type IFrameTypes = {
	title: string
	iframe: {
		title: string
		src: string
	}
	hide3dIcon?: boolean
	fullWidth?: boolean
	anchorId?: string
};

const IFrameEmbed = ({
	title,
	iframe,
	hide3dIcon = true,
	fullWidth,
	anchorId,
}: IFrameTypes) => {
	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '200px 0px',
	});

	return (
		<Styled.VirtualTourEmbed id={anchorId || ''} fullWidth={fullWidth}>
			<Styled.VirtualTourEmbed__Title as="h2">
				{title}

				{hide3dIcon && <SVG name="threeD" aria-hidden="true" />}
			</Styled.VirtualTourEmbed__Title>

			<Styled.VirtualTourEmbed__Inner ref={ref}>
				{inView && (
					<Styled.VirtualTourEmbed__Frame
						src={iframe.src}
						title={iframe.title}
					/>
				)}
			</Styled.VirtualTourEmbed__Inner>
		</Styled.VirtualTourEmbed>
	);
};

export default IFrameEmbed;
