import styled, { css } from 'styled-components';
import { Container } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';
import aspectRatio from '../../../styles/tools/aspectRatio';
import { xlTitleRegular } from '../../atoms/Typography/index.styled';

type IFrameTypes = {
    fullWidth?: boolean;
};

const VirtualTourEmbed = styled(Container)<IFrameTypes>`
    margin-top: ${spacing[48]};
    margin-bottom: ${spacing[48]};

    ${above(
        breakpoints[960],
        () => css`
            margin-top: ${spacing[64]};
            margin-bottom: ${spacing[64]};
        `
    )}

    ${above(
        breakpoints[1440],
        () => css`
            margin-top: ${spacing[80]};
            margin-bottom: ${spacing[80]};
        `
    )}

	${(props) =>
        props.fullWidth === true &&
        css`
            padding: 0 !important;
        `}
`;

const VirtualTourEmbed__Title = styled(xlTitleRegular)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${gap[32]};
    color: var(--primaryForeground);

    svg {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
        margin-left: ${gap[16]};
        fill: currentColor;
    }

    ${above(
        breakpoints[960],
        () => css`
            margin-bottom: ${gap[56]};

            svg {
                width: 40px;
                height: 40px;
                flex: 0 0 40px;
            }
        `
    )}
`;

const VirtualTourEmbed__Inner = styled.div`
    position: relative;
    padding-top: ${aspectRatio('3:2')};
    background-color: var(--primaryAccent);

    ${above(
        breakpoints[960],
        () => css`
            padding-top: ${aspectRatio('15:6')};
        `
    )}

    ${below(
        breakpoints[600],
        () => css`
            padding-top: ${aspectRatio('1:1')};
        `
    )}
`;

const VirtualTourEmbed__Frame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

export default {
    VirtualTourEmbed,
    VirtualTourEmbed__Title,
    VirtualTourEmbed__Inner,
    VirtualTourEmbed__Frame,
};
